import React, { useEffect, useRef } from 'react';
import { useLocation } from '@reach/router';

import {
    sectionBox,
    formWrapper,
    title,
    description,
    success,
    button,
} from './password-reset.module.scss';

import useRtkQueryFormError from '../../hooks/use-rtk-query-form-error';
import { passwordResetForm } from '../../formik/password-reset.form';
import { useUser } from '../../hooks/use-user';
import useTranslations from '../../hooks/use-translations';
import pagesContext from '../../../config/pages-context';

import FormGenerator from '../molecules/form-generator';
import Button from '../atoms/button';

export interface IPasswordResetProps {
    className?: string;
    TitleTag?: React.ElementType;
}

const PasswordReset: React.FC<IPasswordResetProps> = ({ className = '' }) => {
    const t = useTranslations('PasswordReset');
    const userState = useUser();
    const { formikRef, globalErrorMessage } = useRtkQueryFormError(userState.resetPassword.errors);
    const sectionRef = useRef<HTMLElement>(null);
    const { search } = useLocation();
    const urlParams = new URLSearchParams(search);
    const token = urlParams.get('token');

    useEffect(() => {
        formikRef.current?.setFieldValue('token', token || '');
    }, [formikRef, token]);

    useEffect(() => {
        if (!sectionRef.current) return;
        if (userState.resetPassword.isSuccess) {
            sectionRef.current.scrollIntoView();
        }
    }, [userState.resetPassword.isSuccess]);

    return (
        <section className={`${className} ${sectionBox}`}>
            {!userState.resetPassword.isSuccess ? (
                <>
                    <h1 className={title}>{t.title}</h1>
                    <p className={description}>{t.description}</p>
                    <FormGenerator
                        formRef={formikRef}
                        className={formWrapper}
                        formikProps={{
                            onSubmit: (values) => userState.resetPassword.fetch(values),
                        }}
                        name="PasswordResetForm"
                        fields={passwordResetForm}
                        errorMessage={globalErrorMessage}
                        submitLoading={userState.resetPassword.isLoading}
                    />
                </>
            ) : (
                <>
                    <h1 className={`${title} ${success}`}>{t.sentTitle}</h1>
                    <p className={description}>{t.sentDescription}</p>
                    <Button className={button} as={'link'} to={`/${pagesContext.logIn.slug}`}>
                        {t.button}
                    </Button>
                </>
            )}
        </section>
    );
};

export default PasswordReset;
