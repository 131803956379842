import React from 'react';
import { graphql } from 'gatsby';
import { Provider } from 'react-redux';

import { container } from './password-reset.module.scss';

import store from '../aes/redux/store';
import { IPage } from '../models/page.model';

import MainLayout from '../layouts/main-layout';
import PasswordResetSection from '../aes/components/sections/password-reset';

interface IPasswordResetPageProps {
    readonly data: {
        page: IPage | null;
    };
}

const PasswordReset: React.FC<IPasswordResetPageProps> = ({ data }) => {
    const { page } = data;

    return (
        <Provider store={store}>
            <MainLayout page={page} footerType={'simple'} className={container}>
                <PasswordResetSection />
            </MainLayout>
        </Provider>
    );
};

export const query = graphql`
    query ($language: String!, $site: String!) {
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
        page: strapiPage(
            locale: { eq: $language }
            site: { eq: $site }
            isDummyContent: { eq: false }
        ) {
            ...pageFields
        }
    }
`;

export default PasswordReset;
